module directives {
    export module costing {
        export class costingDetailDirective implements ng.IDirective {
            template = `<table class="table table-hover">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Description</th>
                                <th>Column 1</th>
                                <th>Operator</th>
                                <th>Column 2</th>
                                <th>Value</th>
                                <th>Per Unit</th>
                                <th>Actual</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr ng-repeat="costingDetail in ngModel">
                                <td>{{costingDetail.SequenceNumber}}</td>
                                <td>{{costingDetail.Code}}</td>
                                <td>{{costingDetail.Description}}</td>
                                <td>{{costingDetail.Col1Code}}</td>
                                <td>{{costingDetail.FormulaDescription}}</td>
                                <td>{{costingDetail.Col2Code}}</td>
                                <td>{{costingDetail.BaseCurrencyOutputValue}}</td>
                                <td>{{costingDetail.BaseCurrencyOutputUnitValue}}</td>
                                <td>{{costingDetail.ActualValue}}</td>
                              </tr>
                            </tbody>
                        </table>`;
            scope = {
                ngModel: '='
            };

            constructor(public $q: ng.IQService) {
            }

            link = ($scope: ng.IScope, $element: ng.IAugmentedJQuery, att: ng.IAttributes, controllers: ng.IControllerService) => {

            }

            static factory(): ng.IDirectiveFactory {
                const directive = ($q) => new costingDetailDirective($q);
                directive.$inject = ['$q'];

                return directive;
            }
        }

        angular.module("app").directive("gtsCostingDetail", costingDetailDirective.factory());
    }
}